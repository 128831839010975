import React from 'react';
import SEO from '../components/SEO';
import { ImageGrid, ImageCaption } from '../styles/ImageGrid';
import { TripHeader } from '../components/TripHeader';
import Baileykevinpoohwithholyriders from '../assets/images/baileykevinpoohwithholyriders.jpg';
import Baileywithmikecook from '../assets/images/baileywithmike-cook.jpg';
import Baileykevinpoohwithfans from '../assets/images/baileykevinpoohwithfans.jpg';
import Oxfordregulars from '../assets/images/oxfordregulars.jpg';

export default function Page() {
  return (
    <>
      <SEO title="Oxford 2: 2-16-01" />
      <div>
        <TripHeader>
          <h2>Oxford 2</h2>
          <p>2-16-01</p>
          <img
            src={Baileykevinpoohwithholyriders}
            alt="Bailey, Pooh, & Kevin with the Holy Riders"
          />
          <ImageCaption>
            Bailey, Pooh, & Kevin with the Holy Riders
          </ImageCaption>
        </TripHeader>
        <ImageGrid>
          <div>
            <img src={Baileywithmikecook} alt="Bailey with Mike the Cook" />
            <ImageCaption>Bailey with Mike the Cook</ImageCaption>
          </div>
          <div>
            <img src={Baileykevinpoohwithfans} alt="Us with some fans" />
            <ImageCaption>Us with some fans</ImageCaption>
          </div>
          <div>
            <img src={Oxfordregulars} alt="Huddle House Regulars" />
            <ImageCaption>Huddle House Regulars</ImageCaption>
          </div>
        </ImageGrid>
        <p>
          Well let me just tell you about the last venture to the Huddle House.
          Only Bailey, Pooh, and Kevin went, but it really doesn't matter how
          many people go because we had a great time! The weather was awful so a
          lot of people didn't go, but us being the dare devils that we are
          risked tornado warnings, rain, and winds for the Huddle House! We had
          some time to kill before a movie started, so we decided to drive on
          out the that great Huddle House we found last week. The one we so
          affectionately call Oxford 2 since it is the second Huddle House in
          Oxford we visit. Well somthing must have been calling us out there
          because we meet the greatest people ever! We saw Sandra and Mike the
          cook, but we also meet the Holy Riders! A gang that regularly comes to
          this particular Huddle House and have been to Huddle House's all over
          the place-just like we hope to do. They are huge fans and are also
          looking for Huddle House patches to go on their jackets. They are
          really great people! To get in touch with the Holy Riders, E-mail
          their leader Shortcut at shortcut99[at]webtv.net! We also meet other
          fans and had great waffles!
        </p>
      </div>
    </>
  );
}
